//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import singleSpa from 'single-spa';
import { mapActions, mapState, mapGetters } from 'vuex';

import SmsView from './partials/Sms.vue';
import ChatAppsView from './partials/ChatApps.vue';
import VideoView from './partials/Video.vue';
import SupportView from './partials/Support.vue';
import VoiceView from './partials/Voice.vue';
import ProfileSettingsView from './partials/ProfileSettings.vue';
import { handlePackageLimit } from '../common/handle-package-limit';

export default {
  name: 'Overview',

  components: {
    SmsView,
    ChatAppsView,
    VideoView,
    VoiceView,
    SupportView,
    ProfileSettingsView,
  },

  data() {
    return {
      resetForm: false,
      tab: 'profile',
      name: 'User',
      isAdmin: false,
      isFetching: false,
      userData: {},
      showSendButton: false,
      showSmsText: true,
      hasVideoApp: false,
      hasChatApp: false,
      hasVoiceApp: false,
    };
  },

  computed: {
    ...mapState('account', ['userProfile']),
    ...mapState('dashboard', ['smsReport']),
    ...mapGetters('dashboard', ['smsReportSummary', 'prevSmsReportSummary', 'smsReportData', 'reportByDate', 'reportByTopic']),
  },

  created() {
    const user = localStorage.getItem('CPV3_User') || {};

    try {
      this.userData = JSON.parse(user);
      this.userProfile = Object.assign({}, JSON.parse(user));

      this.hasVideoApp = this.userData.Product_VI;
      this.hasChatApp = this.userData.Product_CA;
      this.hasVoiceApp = this.userData.Product_VO;

      if (this.userData.Roles.includes('MultichannelSender_v2')) {
        this.showSendButton = true;
      }

      this.name = this.userData.Firstname || this.userData.Lastname
        ? `${this.userData.Firstname || ''} ${this.userData.Lastname || ''}` : this.userData.Login;
      this.isAdmin = this.userData.AccessLevel === 'A';
    } catch (e) {
      // do  nothing
    }
  },

  beforeDestroy() {
    this.$el.className = '';
  },

  methods: {
    ...mapActions({
      getAuthUser: 'common/getAuthUser',
      getUserProfile: 'account/getUserProfile',
      getSmsReport: 'dashboard/getSmsReport',
    }),

    getUserAlias(userData) {
      if (Object.keys(userData).length) {
        if (userData.Firstname && userData.Lastname) {
          const firstName = userData.Firstname.trim().charAt(0);
          const lastName = userData.Lastname.trim().charAt(0);

          return `${firstName}${lastName}`.toUpperCase();
        }

        if (userData.Firstname && !userData.Lastname) {
          return userData.Firstname.trim().charAt(0).toUpperCase();
        }

        if (!userData.Firstname && userData.Lastname) {
          return userData.Lastname.trim().charAt(0).toUpperCase();
        }

        if (userData.Login) {
          return userData.Login.charAt(0).toUpperCase();
        }
      }

      return '';
    },

    hasEntitlement(entitlement) {
      return handlePackageLimit(entitlement);
    },

    getChange(currentTotal, prevTotal) {
      if (currentTotal === 0 && prevTotal === 0) return 0;
      if (currentTotal === 0 && prevTotal !== 0) return -100;
      if (currentTotal !== 0 && prevTotal === 0) return 100;

      return ((currentTotal - prevTotal) / prevTotal) * 100;
    },

    showProfile(tab) {
      let t = tab;
      if (tab === 'account' && !this.isAdmin) {
        t = 'profile';
      }

      const event = new Event(t);

      window.dispatchEvent(event);
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    navigateTo(app) {
      singleSpa.navigateToUrl(`/${app}`);
    },
  },
};
